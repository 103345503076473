import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import VueScrollTo from 'vue-scrollto'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSquareFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
library.add(faSquareFacebook)
library.add(faLinkedin)


createApp(App).use(store).use(router).use(VueScrollTo,{
    container: "body",
    duration: 500,
    lazy: false,
    easing: "ease",
    offset: -100,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
}).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
