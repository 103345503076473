import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: HomeView
  },
  {
    path: '/partners',
    name: 'partners',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: HomeView
  },
  {
    path: '/practices',
    name: 'practices',
    redirect: {
      name: 'digital-forensics-incident-response'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PracticesView.vue'),
    children: [
      {
        path: '/digital-forensics-incident-response',
        name: 'digital-forensics-incident-response',
        component: () => import('../views/practices/DigitalForencisView.vue')
      },
      {
        path: '/research-science',
        name: 'research-science',
        component: () => import('../views/practices/ResearchScienceView.vue')
      },
      {
        path: '/consulting-practice',
        name: 'consulting-practice',
        component: () => import('../views/practices/ConsultingPracticeView.vue')
      },
      {
        path: '/research-development',
        name: 'research-development',
        component: () => import('../views/practices/ResearchDevelopmentView.vue')
      },
      {
        path: '/cyber-security-enablement',
        name: 'cyber-security-enablement',
        component: () => import('../views/practices/CyberSecurityView.vue')
      },
      {
        path: '/cert-response-center',
        name: 'cert-response-center',
        component: () => import('../views/practices/CertView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode:history,
  routes,
  scrollBehavior: (to, from, savedPosition)=>{
    if(to.hash){
      return {
        el: to.hash
      }
    }
    return savedPosition
  }
})

export default router
