<template>
  <div class="min-h-screen" id="top">
    <section id="header" class="bg-white">

      <div class="h-96 flex justify-center items-center bg-red-800 text-white">
        <div class="container m-auto text-center">
          <img src="../../public/favicon.png" class="w-24 my-4 m-auto" alt="">
          <h1 class="text-4xl z-40">Securing the Filipino Nation</h1>
          <p class="z-40">Cyber Security Philippines - CERT &copy;</p>
          <router-link to="practices" class="z-40 relative">
            <button
              class="bg-yellow-500 text-white p-5 m-5 hover:bg-red-700 transition hover:ring-yellow-500 hover:ring-4">
              How We Do IT
            </button>
          </router-link>
        </div>
      </div>
      <div id="particles" class="w-full h-96 absolute top-0 z-30"></div>
      <VueParticle domId='particles' :config="particleConfig" />
      <div class="container mx-auto justify-center items-center">
        <div class="flex-none md:flex flex-wrap items-start py-10 justify-center">
          <div class="md:basis-1/3 shrink-0 grow-0 flex items-center justify-start">
            <div class="p-5 self-start">
              <DocumentIcon class="h-12 w-12 text-red-900 drop-shadow-lg" />
            </div>
            <div class="text-left">
              <div class="text-xl text-red-900 font-bold drop-shadow-lg">Advisories & Vulnerabilities</div>
              <p class="font-bold drop-shadow-lg shadow-black">Meltdown and Spectre</p>
              <small class="italic">by CSP-CERT&copy; Blue Team</small>
            </div>
          </div>
          <div class="md:basis-1/3 shrink-0 grow-0 flex items-center justify-start">
            <div class="p-5 self-start">
              <MagnifyingGlassIcon class="h-12 w-12 text-red-900 drop-shadow-lg" />
            </div>
            <div class="text-left">
              <div class="text-xl text-red-900 font-bold drop-shadow-lg">Malware Research</div>
              <p class="font-bold drop-shadow-lg shadow-black">Patching Malware to Help with Analysis</p>
              <small class="italic">by CSP-CERT&copy; Research Science</small>
            </div>
          </div>
          <div class="md:basis-1/3 shrink-0 grow-0 flex items-center justify-start">
            <div class="p-5 self-start">
              <Cog8ToothIcon class="h-12 w-12 text-red-900 drop-shadow-lg" />
            </div>
            <div class="text-left">
              <div class="text-xl text-red-900 font-bold drop-shadow-lg">Articles</div>
              <p class="font-bold drop-shadow-lg shadow-black">Cyber Summit 2018: "Philippine Army Cyber Security
                Preparedness Moving Beyond Compliance"</p>
              <small class="italic">by CSP-CERT&copy; Red Team</small>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-black text-white p-10" id="about">
      <!-- Trusted Leader -->
      <div class="container m-auto">
        <h2 class="text-4xl text-center my-20 text-yellow-400">A credible and trusted leader in Cybersecurity</h2>
        <p class="text-justify">CyberSecurity Philippines CERT&#174; is a non-profit Computer Security Incident Response
          Team (CSIRT) recognized as the first registered Computer Emergency Response Team (CERT®) in the Philippines.
          CERT® is a registered trademark under the Division of the Software Engineering Institute (SEI) located at
          Carnegie Mellon University in Pennsylvania, United States. CERT® was sanctioned by the United States
          Department of Defense (DoD) to deal with internet security incidents 25 years ago and remains a trusted
          authority dedicated to improving the security and resilience of computer systems and networks in the field of
          CyberSecurity.</p>
        <p class="text-justify mt-10">A Computer Security Incident Response Team (CSIRT) is a service organization
          responsible for receiving, reviewing, and responding to computer security incident reports and activities. We
          study and solve problems with widespread cybersecurity implications, conduct research & development and
          provide advisories on security compromises under the Philippines Autonomous System Number (ASN). We are also
          collaborating with various local government units, law enforcement, schools, and universities to help improve
          our country's cybersecurity advocacy to develop cyber defense skills and future employment. As part of our
          vision, we help determine their security-related practices' effectiveness and take them to the next level of
          the cybersecurity maturity curve. Concurrently, we collaborate closely with other computer security incident
          response teams and the different cybersecurity initiatives in the Philippines and abroad.</p>
      </div>
      <!-- Journey -->
      <div class="container m-auto">
        <h2 class="text-4xl text-center mt-20 text-yellow-400">Our Journey</h2>
        <div class="h-1/2 flex justify-center items-center">
          <div class="md:w-1/2 m-auto my-20">
            <ol class="relative border-l-4 border-red-800 dark:border-red-800">
              <li class="mb-10 ml-6">
                <span
                  class="absolute flex items-center justify-center w-6 h-6 md:w-12 md:h-12 bg-red-200 rounded-full -left-3 md:-left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-red-900">
                  <svg aria-hidden="true" class="w-3 md:w-7 h-3 md:h-7 text-red-600 dark:text-red-400"
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"></path>
                  </svg>
                </span>
                <h3 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">CSP CERT&#174; was officially
                  registered in CERT.org</h3>
                <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">August 26,
                  2016</time>
                <!-- <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p>
                  <a href="#" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"><svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd"></path></svg> Download ZIP</a> -->
              </li>
              <li class="mb-10 ml-6">
                <span
                  class="absolute flex items-center justify-center w-6 h-6 md:w-12 md:h-12 bg-red-200 rounded-full -left-3 md:-left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-red-900">
                  <svg aria-hidden="true" class="w-3 md:w-7 h-3 md:h-7 text-red-600 dark:text-red-400"
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"></path>
                  </svg>
                </span>
                <h3 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">CSP CERT&#174; soft launch in the
                  Philippines</h3>
                <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">December 3th,
                  2016</time>
                <!-- <p class="text-base font-normal text-gray-500 dark:text-gray-400">All of the pages and components are first designed in Figma and we keep a parity between the two versions even as we update the project.</p> -->
              </li>
              <li class="ml-6">
                <span
                  class="absolute flex items-center justify-center w-6 h-6 md:w-12 md:h-12 bg-red-200 rounded-full -left-3 md:-left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-red-900">
                  <svg aria-hidden="true" class="w-3 md:w-7 h-3 md:h-7 text-red-600 dark:text-red-400"
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                      clip-rule="evenodd"></path>
                  </svg>
                </span>
                <h3 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">CSP CERT&#174; officially
                  accredited as the first authorized CERT in the Philippines</h3>
                <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">January 18,
                  2018</time>
                <!-- <p class="text-base font-normal text-gray-500 dark:text-gray-400">Get started with dozens of web components and interactive elements built on top of Tailwind CSS.</p> -->
              </li>
            </ol>
          </div>
        </div>
      </div>


      <!-- Practices -->
      <div class="container m-auto">
        <h2 class="text-4xl text-center m-20 text-yellow-400">Our Practices</h2>

        <div class="flex-none md:flex flex-wrap justify-center">
          <div class="basis-1/4 grow-0 shrink-0 bg-white m-5 h-370px flex flex-col justify-between">
            <div class="image image_dfir h-3/5"></div>
            <p class="text-black p-5 font-bold drop-shadow-lg">Digital Forensics & Incident Response</p>
            <div>
              <router-link to="digital-forensics-incident-response" v-scroll-to="'#article'">
                <button class="bg-yellow-500 text-white p-5 hover:bg-red-800 transition">
                  <span class="font-bold drop-shadow-lg shadow-black">Learn More</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="basis-1/4 grow-0 shrink-0 bg-white m-5 h-370px flex flex-col justify-between">
            <div class="image image_rs h-3/5"></div>
            <p class="text-black p-5 font-bold drop-shadow-lg">Research Science</p>
            <div>
              <router-link to="research-science" v-scroll-to="'#article'">
                <button class="bg-yellow-500 text-white p-5 hover:bg-red-800 transition">
                  <span class="font-bold drop-shadow-lg shadow-black">Learn More</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="basis-1/4 grow-0 shrink-0 bg-white m-5 h-370px flex flex-col justify-between">
            <div class="image image_cp h-3/5"></div>
            <p class="text-black p-5 font-bold drop-shadow-lg">Consulting Practice</p>
            <div>
              <router-link to="consulting-practice" v-scroll-to="'#article'">
                <button class="bg-yellow-500 text-white p-5 hover:bg-red-800 transition">
                  <span class="font-bold drop-shadow-lg shadow-black">Learn More</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="basis-1/4 grow-0 shrink-0 bg-white m-5 h-370px flex flex-col justify-between">
            <div class="image image_rnd h-3/5"></div>
            <p class="text-black p-5 font-bold drop-shadow-lg">Research & Development</p>
            <div>
              <router-link to="research-development" v-scroll-to="'#article'">
                <button class="bg-yellow-500 text-white p-5 hover:bg-red-800 transition">
                  <span class="font-bold drop-shadow-lg shadow-black">Learn More</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="basis-1/4 grow-0 shrink-0 bg-white m-5 h-370px flex flex-col justify-between">
            <div class="image image_cse h-3/5"></div>
            <p class="text-black p-5 font-bold drop-shadow-lg">Cyber Security Enablement</p>
            <div>
              <router-link to="cyber-security-enablement" v-scroll-to="'#article'">
                <button class="bg-yellow-500 text-white p-5 hover:bg-red-800 transition">
                  <span class="font-bold drop-shadow-lg shadow-black">Learn More</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="basis-1/4 grow-0 shrink-0 bg-white m-5 h-370px flex flex-col justify-between">
            <div class="image image_cert h-3/5"></div>
            <p class="text-black p-5 font-bold drop-shadow-lg">CERT&#174; Response Center</p>
            <div>
              <router-link to="cert-response-center" v-scroll-to="'#article'">
                <button class="bg-yellow-500 text-white p-5 hover:bg-red-800 transition">
                  <span class="font-bold drop-shadow-lg shadow-black">Learn More</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Partners -->
    <section class="py-10 bg-white" id="partners">
      <h3 class="text-4xl font-bold text-black inline-block border-2 p-3 border-black">Partners</h3>

      <div class="container mx-auto">
        <div class="flex-none md:flex flex-wrap justify-center">
          <div v-for="partner in partners" :key="partner.image"
            class="basis-1/3 shrink-0 grow-0 hover:scale-110 transition">
            <a :href="partner.link" target="__blank">
              <div class="image-fit h-24 px-6 mx-5 my-20" :class="partner.class"></div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <!-- Affiliation -->
    <section class="bg-white">
      <div class="container m-auto py-10 o">
        <h3 class="text-4xl font-bold text-black inline-block border-2 p-3 border-black">Affiliates</h3>

        <div class="container mx-auto">
          <div class=" flex-none md:flex flex-wrap">
            <div v-for="affiliate in affiliations" :key="affiliate.image" :class="affiliate.width"
              class="shrink-0 grow-0 hover:scale-110 transition">
              <a :href="affiliate.link" target="__blank">
                <div class="image-fit h-24 px-6 mx-5 my-20" :class="affiliate.class"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="bg-white image contact-us" id="contact">
      <div class="container m-auto flex flex-col justify-center items-center min-h-85%">
        <h1 class="text-white text-5xl drop-shadow-md shadow-black">WE are here to HELP</h1>
        <p class="text-white text-2xl drop-shadow-md shadow-black">Follow US on Our Socials for the Latest Updates
        </p>
        <div>
          <a href="https://facebook.com/cspcert" target="__blank"
            class="text-yellow-400 hover:text-yellow-500 m-2 shadow-black drop-shadow-lg">
            <font-awesome-icon icon="fa-brands fa-square-facebook fa-10x" size="4x" />
          </a>
          <a href="https://www.linkedin.com/company/cspcert/" target="__blank"
            class="text-yellow-400 hover:text-yellow-500 m-2 shadow-black drop-shadow-lg">
            <font-awesome-icon icon="fa-brands fa-linkedin" size="4x" />
          </a>
        </div>
      </div>
    </section>

    <section>
      <div class="container m-auto py-8 px-4">
        <div class="grid md:grid-cols-2 gap-8 text-white">
          <div class="md:col-span-1 text-left">
            <div class="text-2xl font-bold">Get in Touch</div>
            <div class="font-bold text-xl">Please fill out the form and we will be in touch as soon as we can</div>
            <form method="POST" action="https://formspree.io/contact_us@cert.ph">
              <input type="hidden" name="_subject" value="CSP-CERT Website Contact">
              <input type="hidden" name="Referrer" value="https://www.cert.ph">
              <div class="my-4">
                <label for="price" class="block text-md font-bold">Your email address</label>
                <input type="text" name="email" class="block w-full rounded-md border-gray-300 p-2 text-black"
                  placeholder="Your email address">
              </div>
              <div class="my-4">
                <label for="price" class="block text-md font-bold">Your message</label>
                <textarea name="message" rows="5" type="text"
                  class="block w-full rounded-md border-gray-300 p-2 text-black" placeholder="Message"></textarea>
              </div>
              <button type="submit" class="bg-yellow-400 py-4 px-8 hover:bg-yellow-500"><span
                  class="drop-shadow-md shadow-black">SUBMIT</span></button>
            </form>
            <address class="my-4">
              For memberships, partnerships, grants and other related sponsorships or general inquiries please get in
              touch with our Program Development group at: <br>
              <a href="mailto:contact_us@cert.ph" class="text-yellow-400 hover:underline">contact_us@cert.ph</a><br>
            </address>
            <address class="my-4">
              For Breaches or any urgent Cyber Crime activity please contact our CERT® Response Center at: <br>
              <a href="mailto:emergency@cert.ph" class="text-yellow-400 hover:underline">emergency@cert.ph</a><br>
            </address>
          </div>
          <div class="md:col-span-1 text-left">
            <div class="text-2xl font-bold">Contact us:</div>
            <address>
              <u>CSP Cert®</u><br>
              Panorama Tower 7th Floor Lane A,<br>
              corner 34th St, Taguig, <br> 1632 Metro Manila <br>
              <abbr title="Phone">P:</abbr> (+632) 224-5637<br>
              <abbr title="Fax">F:</abbr> (+632) 224-5638<br>
            </address>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61787.635649204036!2d120.98399889682139!3d14.557585010045631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c816c51eeef9%3A0x8958f1aae7537a39!2sCyber%20Security%20Philippines%20Computer%20Emergency%20Response%20Team%C2%AE!5e0!3m2!1sen!2sph!4v1673776255958!5m2!1sen!2sph"
              class="w-full my-5" height="450" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>

            <a href="/files/CSP-CERT-RFC2350.pdf" target="__blank">
              <button class="bg-yellow-400 py-4 px-8 hover:bg-yellow-500">
                <span class="drop-shadow-md shadow-black">Click here for RFC2350</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>

import { DocumentIcon, MagnifyingGlassIcon, Cog8ToothIcon } from '@heroicons/vue/24/outline'

import VueParticle from 'vue-particlejs';



export default {
  name: 'HomeView',
  components: {
    DocumentIcon,
    MagnifyingGlassIcon,
    Cog8ToothIcon,
    VueParticle
  },
  data() {
    return {
      particleConfig: {
        particles: {
          number: { value: 80, density: { enable: true, value_area: 800 } },
          color: { value: "#ffffff" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
            image: { src: "img/github.svg", width: 100, height: 100 }
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "repulse" },
            onclick: { enable: true, mode: "push" },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      },
      partners: [
        {
          class: 'microsoft',
          link: 'https://www.microsoft.com/en-ph/'
        },
        {
          class: 'belkasoft',
          link: 'https://belkasoft.com/'
        },
        // {
        //   class: 'nexusguard',
        //   link: 'https://www.nexusguard.com/'
        // },
        // {
        //   class: 'msi-ecs',
        //   link: 'https://www.msi-ecs.com.ph/'
        // },
        // {
        //   class: 'lantium',
        //   link: 'https://www.lantium.asia/'
        // }
      ],
      affiliations: [

        {
          class: 'cert',
          link: 'https://www.sei.cmu.edu/education-outreach/computer-security-incident-response-teams/authorized-users/index.cfm',
          width: 'md:w-full'
        },
        {
          class: 'cybersecurity-malaysia',
          link: 'http://www.cybersecurity.my/en/index.html',
          width: ' md:w-1/2'
        },
        {
          class: 'mycert',
          link: 'https://www.mycert.org.my/en/',
          width: 'md:w-1/2'
        },
        {
          class: 'honeynet',
          link: 'https://www.honeynet.org/',
          width: 'md:w-1/3'
        },
        {
          class: 'owasp',
          link: 'https://www.owasp.org/',
          width: 'md:w-1/3'
        },
        {
          class: 'shadowserver',
          link: 'https://www.shadowserver.org/wiki/',
          width: 'md:w-1/3'
        }
      ]
    }
  }
}
</script>

<style scoped>
.image {
  background-color: gray;
  background-position: center;
  background-size: cover;
}

.image-fit {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.h-370px {
  height: 400px;
}

.image_cert {
  background-image: url('../assets/img/practice/logo6_CRC.jpg')
}

.image_cse {
  background-image: url('../assets/img/practice/logo5_CSE.jpg');
}

.image_rnd {
  background-image: url('../assets/img/practice/logo4_RnD.jpg');
}

.image_cp {
  background-image: url('../assets/img/practice/logo3_CPrac.jpg')
}

.image_rs {
  background-image: url('../assets/img/practice/logo2_RS.jpg');
}

.image_dfir {
  background-image: url('../assets/img/practice/logo1_DFIR.jpg');
}


.microsoft {
  background-image: url('../assets/img/partners/microsoft.png');
}

.belkasoft {
  background-image: url('../assets/img/partners/belkasoft.png');
}

.lantium {
  background-image: url('../assets/img/partners/lantium.png');
}

.msi-ecs {
  background-image: url('../assets/img/partners/msi-ecs.png');
}

.nexusguard {
  background-image: url('../assets/img/partners/nexusguard.png');
}

.cert {
  background-image: url('../assets/img/affiliates/CERT_Authorized_Use_identifier.jpg');
}

.cybersecurity-malaysia {
  background-image: url('../assets/img/affiliates/cybersecurity-malaysia.png');
}

.mycert {
  background-image: url('../assets/img/affiliates/mycert.png');
}

.honeynet {
  background-image: url('../assets/img/affiliates/honeynet.png');
}

.owasp {
  background-image: url('../assets/img/affiliates/owasp.png');
}

.shadowserver {
  background-image: url('../assets/img/affiliates/shadowserver.png');
}

.contact-us {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../assets/img/concierge.jpg');
}
</style>
