<template>
    <nav class="sticky top-0 z-50 bg-black border-b-8 border-red-800">
        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div class="relative flex items-center justify-between">
                <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <button @click="toggleMenu" type="button"
                        class="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        aria-controls="mobile-menu" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>

                        <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>

                        <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-between py-8 md:py-0">
                    <div class="flex flex-shrink-0 items-center">
                        <router-link to="/#" v-scroll-to="'#top'" header-logo>
                            <img src="../assets/header.png" class="block h-8 w-auto lg:hidden" />
                            <img src="../assets/header.png" class="hidden h-8 w-auto lg:block" />
                        </router-link>
                    </div>
                    <div class="hidden sm:ml-6 sm:block">
                        <div class="flex">
                            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                            <router-link to="/about" v-scroll-to="'#about'" 
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-8 text-md"
                                aria-current="page">
                                About
                            </router-link>

                            <router-link to="/practices" v-scroll-to="'#top'"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-8 text-md" 
                                aria-current="page">
                                Practices
                            </router-link >

                            <router-link to="/partners" v-scroll-to="'#partners'"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-8 text-md">
                                Partners & Affiliations
                            </router-link>

                            <router-link to="/contact" v-scroll-to="'#contact'"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-8 text-md">Contact</router-link >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile menu, show/hide based on menu state. -->
        <div class="relative top-0">
            <transition name="slide">
            <div class="sm:hidden bg-black" ref="mobileMenu" v-show="!menuHidden">
                <div class="space-y-1 px-2 pt-2 pb-3 z-50 relative">
                    <router-link to="/about" v-scroll-to="'#about'" @click="toggleMenu" class="text-md text-white block py-4"
                        >About</router-link>

                    <router-link to="/practices" v-scroll-to="'#practices'" @click="toggleMenu"
                        class="text-md text-white block py-4">Practices</router-link>

                    <router-link to="/partners" v-scroll-to="'#partners'" @click="toggleMenu"
                        class="text-md text-white block py-4">Partners & Affiliations</router-link>

                    <router-link to="/contact" v-scroll-to="'#contact'" @click="toggleMenu"
                        class="text-md text-white block py-4">Contact</router-link>
                </div>
            </div>
            </transition>
        </div>
    </nav>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const mobileMenu = ref(null);
const menuHidden = ref(true);

const toggleMenu = () =>{
    menuHidden.value = !menuHidden.value;
}

onMounted(()=>{
})
</script>