<template>
  <NavBar />
  <div class="relative w-full">
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  </div>
  <div class="bg-black text-white py-8 border-t-8 border-red-800">Copyright &copy; | Cyber Security Philippines -
    CERT&#174;;</div>
</template>

<style>

body {
  background-color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-active:not([header-logo]) {
  background-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  position:absolute;
  width: inherit;
  top:0;
}

.slide-enter-active,
.slide-leave-active {
  transform-origin: top center;
  transition: transform .5s ease, opacity .5s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: scaleY(0);
  position: absolute;
  top: 0;
  width: 100%;
}

.min-h-85\% {
  min-height: 85vh;
}
</style>


<script setup>
import NavBar from '@/components/NavBar.vue'
import { onMounted } from 'vue';


onMounted(() => {

})

</script>